import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/packages/villa-clementine/src/gatsby/gatsby-tpl/mdx-layout-default.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Cannes`}</h2>
    <p>{`Cannes a l’avantage d’être une ville qui peut se `}<a href="http://www.cannes.com/images/stories/docs/tourisme/visitez_cannes/plancannesapieds.pdf">{`visiter à pied`}</a>{`.
C’est par la vieille ville et son célèbre `}<strong parentName="p">{`Suquet`}</strong>{` que nous vous conseillons de débuter votre visite de Cannes.
Vous pourrez y accéder par divers versants en empruntant des ruelles pentues, entrecroisées d’escaliers pittoresques où le piéton est roi.
Formidable point de vue sur la ville, le port, la Croisette, les îles de Lérins et sur une large partie de la baie de Cannes.
À voir : les remparts, le musée de la Castre, la tour carrée et les bâtiments religieux.
Ne manquez pas au centre ville : `}<a href="http://freeriders2.over-blog.com/article-le-marche-forville-de-cannes-79751556.html">{`Le marché Forville `}</a>{` ( fruits - légumes - fleurs - poisson…) dont l’allée centrale est réservée aux producteurs locaux,
et ou les pecheurs proposent le poisson pêché du jour. Ouvert tous les jours, de 7h00 à 13h00 sauf le lundi
Lieu mythique et incontournable de Cannes `}<strong parentName="p">{`la Croisette`}</strong>{` sera un passage obligé mais également un lieu de promenade rempli de découverte.`}</p>
    <p>{`Si vous n’appreciez pas la marche a pied `}<a href="http://www.cannes-petit-train.com/">{`Le Train du Cinéma`}</a>{` vous propose au départ du Palais des Festivals, côté plages,
deux visites guidées à la découvertes de deux facettes de Cannes : History Tour (Suquet, centre historique) et le Croisette Tour (Croisette, Palm Beach).`}</p>
    <h2>{`Festival Pyrotechnique`}</h2>
    <p>{`En matière de pyrotechnie, Cannes va plus loin que le traditionnel feu d’artifice: elle organise dans la baie un grand concours de spectacles pyromusicaux.
Les plus grands spécialistes mondiaux doivent marier mélodies et couleurs et les mises en scène atteignent des sommets de sophistication.
Chaque année six soirées du `}<a href="http://www.festival-pyrotechnique-cannes.com">{`Festival Pyrotechnique`}</a>{` sont programmées du `}<strong parentName="p">{`14 juillet a fin août`}</strong>{`.
Pour une soirée inoubliable, vous pouvez y assister `}<a href="http://www.trans-cote-azur.com/cannes-feux-d-artifices.php">{`en bateau.`}</a></p>
    <h2>{`Les Plages`}</h2>
    <p>{`Le littoral, d’une longueur totale de 15 kilomètres comporte 7,6 kilomètres de plages, dont 13 plages publiques, 2 en`}<a href=" http://www.cannes.com/dmdocuments/dep_plages.pdf">{` régie municipale `}</a>{` et 33 en `}<a href="http://www.plagesdecannes.com/liste_plages.php">{`concession`}</a>{`.
La `}<a href="http://www.cannes.com/index.php?option=com_content&view=article&id=93&Itemid=2457696&lang=fr">{`qualité des eaux de baignade `}</a>{`y est régulièrement controlée.`}</p>
    <h2>{`Les iles de Lerins`}</h2>
    <p>{`Situées au sud-est de la Croisette, en face de Cannes, `}<a href="http://www.cannes.travel/spip.php?article452">{`les iles de Lerins `}</a>{`séparent le golfe de la Napoule, à l’ouest, du golfe de Juan, à l’est.
L’archipel de Lérins, baignant dans une Méditerranée limpide, se compose de deux grandes îles :
Sainte-Marguerite, la plus étendue, célèbre pour son fort qui aurait abrité le célèbre Homme au masque de fer.
Saint-Honorat, plus petite, bien connue pour son monastère.
`}<strong parentName="p">{`Une journée pique-nique aux iles est inoubliable.`}</strong>{`
L’ile de Saint Honorat est desservie par la compagnie `}<a href="http://www.cannes-ilesdelerins.com/">{`Planaria `}</a>{`.
l’ile de Sainte Marguerite est desservie par la compagnie `}<a href="http://www.trans-cote-azur.com/cannes-ile-sainte-marguerite.php">{`Trans Côte d’Azur`}</a>{`.`}</p>
    <h2>{`Location de bateau`}</h2>
    <p>{`Pourquoi pas louer un `}<a href="http://www.location-bateaux-cannes.com/">{`bateau `}</a>{`avec ou sans permis, pour la journée ou l’après midi?`}</p>
    <h2>{`Marineland`}</h2>
    <ul>
      <li parentName="ul"><a href="http://www.marineland.fr/">Marineland</a> est le plus beau parc marin en Europe. Vous allez y découvrir dans un cadre superbe les grands mamifères marins qui peuplent nos océans.
Du requin, à l'orque en passant bien sûr par le dauphin.</li>
      <li parentName="ul">{`ESPACE MARINELAND c’est 3 autres parcs à thème pour s’amuser sous le soleil de la Côte d’Azur.`}</li>
      <li parentName="ul">{`La Ferme du Far West : Un univers où les enfants seront ravi de vivre au rythme des animations maquillage,tour de poneys, tour de barque, les Cabanes suspendues !`}</li>
      <li parentName="ul">{`Adventure Golf : Dépaysement assurés au beau milieu des décors inspirés dans plus grands voyages de Jules Verne : 3 parcours de 18 trous aux mille obstacles inattendus.`}</li>
      <li parentName="ul">{`Aquasplash : dans le plus grand parc aquatique de la Côte d’Azur,`}</li>
    </ul>
    <h2>{`Vision sous-marine`}</h2>
    <p>{`Offrez vous une promenade en mer dans le site enchanteur du cap d’Antibes dit “la baie des milliardaires”.
Le bateau `}<a href="http://www.theoule-sur-mer.org/index.php?id=4481">{`Visiobulle`}</a>{` vous embarque pour une promenade en mer d’une heure environ.
Depuis sa coque de verre, effleurant roches et herbiers de posidonies peuplés de bancs de saupes, sars, oursins et étoiles de mer,
vous découvrirez les merveilles du monde du silence.`}</p>
    <h2>{`Grasse`}</h2>
    <p>{`Capitale mondiale du parfum, la ville de Grasse est aussi réputée pour ses fameuses parfumeries, qui proposent des visites guidées gratuites:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.fragonard.com/"
        }}>{`Fragonard`}</a></li>
      <li parentName="ul"><a href="http://www.galimard.com/">Galimard</a></li>
      <li parentName="ul"><a href="http://www.molinard.com/">Molinard</a></li>
    </ul>
    <p>{`Découvrez également sa vieille ville médiévale et son `}<a href="http://www.museesdegrasse.com/">{`Musée international de la parfumerie`}</a>{`.`}</p>
    <h2>{`Le Train des pignes`}</h2>
    <p>{`Trait d’union entre la Méditerranée et les Alpes, le Train des Pignes est un élément clé de la mémoire collective des vallées qu’il traverse depuis 1891.
Une ligne de train mythique qui vous ravira par la beauté des paysages traversés.
Offrez vous une journée d’excursion dans l’arriere pays niçois avec les autorails des `}<a href="http://www.trainprovence.com/">{`chemins de fer de provence`}</a>{`.
Ou mieux, avec le `}<strong parentName="p">{`véritable train des Pignes à vapeur`}</strong>{` ressuscité par les amoureux de la ligne du, `}<a href="http://gecp.asso.fr/">{`groupe d’etude pour les chemins de fer de provence `}</a>{`.
qui circule de mai à octobre, sur une portion de la ligne – Puget-Théniers à Annot ou Villars-sur-Var à Puget-Théniers,
vous retrouverez les voitures aux banquettes de bois verni, l’odeur de la suie et le ronflement des tonnes d’eau en ébullition de la machine à vapeur.`}</p>
    <h2>{`Loisirs d’enfants`}</h2>
    <p>{`Vous cherchez des activités de loisir pour les enfants voici `}<a href="http://www.momesdazur.com">{`Mômes d’azur`}</a>{` l’incontournable guide.`}</p>
    <h2>{`Accrobranches`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.azur-labyrinthe.com/pitchoun_forest.html"
        }}>{`Pitchoun Forest`}</a>{` est un parcours d’accrobranches en ligne de vie automatique, qui fait decouvrir la vie de la foret aux plus petits (dès 3 ans) tout en s’amusant!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.leboisdeslutins.com/index2.html"
        }}>{`Le bois des lutins`}</a>{` c’est 3 heures d’aventures insolites pour toute la famille. Un spectaculaire parcours de filets surplombant un hectare et demi de jeux et de surprises.`}</li>
    </ul>
    <h2>{`Randonnée, VTT, Canyons`}</h2>
    <ul>
      <li parentName="ul">{`Les Alpes maritimes offrent un grand nombre de sites de randonnée pedestre, de circuits de VVT, ou de descentes de clues et canyons.`}</li>
      <li parentName="ul">{`Le conseil général vous propose sur son site `}<a href="http://www.randoxygene.org/pge/rando_pe/rubrique_rando.php?rubrique=1&zone=1">{`Randoxygene`}</a>{` toutes les informations utiles pour ces activités.`}</li>
    </ul>
    <h2>{`Les Loups du Mercantour`}</h2>
    <p>{`A Saint Martin Vésubie, dans le massif du Mercantour, allez découvrir `}<a href="http://www.alpha-loup.com/accueil.php">{`Alpha`}</a>{`, le parc des loups.
Dans le cadre somptueux du vallon du Boréon, l’équipe des soigneurs d’Alpha vous mènera à la rencontre du prédateur légendaire…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      